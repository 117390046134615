import axios from "axios";

export default function apiAgent() {
  const baseUrl = "https://api.acuitydata.co.za/api/";
  const domain = "motorsuredirect.acuitydata.co.za";
  const username = "bba70cd3-d852-4b37-9b8d-f3bba1bb92b8";
  const password = "4f8b6d85-3c79-4343-a26d-ac7d7814b027";
  const basicAuth = "Basic " + btoa(username + ":" + password);

  const reference = () => {
    let refURL = window.location.href;
    return refURL.indexOf("ref=") === -1 ? "" : refURL.split("ref=")[1];
  };

  const lookup = {
    make: () => {
      const data = axios
        .get(`${baseUrl}lookup/make?reference=${reference()}`, {
          headers: {
            Authorization: basicAuth,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //return error;
          console.log(error);
          alert("Some error occurred, Please try again")
        });
      return data;
    },

    model: (curCopmpany) => {
      const data = axios
        .get(`${baseUrl}lookup/model/${curCopmpany}?reference=${reference()}`, {
          headers: {
            Authorization: basicAuth,
          },
        })
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //return error;
          console.log(error);
          alert("Some error occurred, Please try again")
        });
      return data;
    },
    year: (curCopmpany, curModel) => {
      const data = axios
        .get(
          `${baseUrl}lookup/year/${curCopmpany}/${curModel}?reference=${reference()}`,
          {
            headers: {
              Authorization: basicAuth,
            },
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //return error;
          console.log(error);
          alert("Some error occurred, Please try again")
        });
      return data;
    },
    variant: (curCopmpany, curModel, curYear) => {
      const data = axios
        .get(
          `${baseUrl}lookup/variant/${curCopmpany}/${curModel}/${curYear}?reference=${reference()}`,
          {
            headers: {
              Authorization: basicAuth,
            },
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //return error;
          console.log(error);
          alert("Some error occurred, Please try again")
        });
      return data;
    },
    register: (bodyData) => {
      bodyData.reference = reference();
      let data = axios
        .post(baseUrl + `register?reference=${reference()}`, bodyData, {
          headers: {
            Authorization: basicAuth,
          },
        })
        .then(function (response) {
          return response.data;
        })
        .catch(function (error) {
          console.log(error);
          alert("Some error occurred, Please try again");
        });

      return data;
    },
    report: (reportKey) => {
      const data = axios
        .get(
          `${baseUrl}report/${reportKey}?reference=${reference()}&domain=${domain}`,
          {
            headers: {
              Authorization: basicAuth,
            },
          }
        )
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          //return error;
          console.log(error);
          alert("Some error occurred, Please try again ")
        });
      return data;
    },
  };
  const userTheme = {
    clientInfo: (subDomain) => {
      let data = {
        domain: domain,
        client_logo: "./assets/Motorsure Logo variations.png",
        theme_settings: {
          primary_color: "rgb(16 132 134)",
          alternative_color: "rgb(16 97 97)",
          secondary_color: "rgb(17 95 95)",
        },
      };
      return data;
    },
  };
  return {
    lookup,
    userTheme,
    reference,
  };
}
